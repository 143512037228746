import React from 'react';

const Projects = () => {

    return (
        <div className="project-container">
            <div className="panel">
                <div className='image'>
                    <img src="/embezuri.png" alt="Embe Zuri" />
                </div>
                <div className='content'>
                    <h2>Embe Zuri</h2>
                    <p>
                        A web app integrated with a Deep Learning AI model to predict mango crop diseases. Created using Flask Python framework and SQL on the backend and React JS on the frontend. The Deep Learning model uses Tensorflow Machine Learning platform.
                    </p>
                    <button onClick={() => window.open('https://embezuri.online', '_blank')}>Visit site</button>
                </div>
            </div>

            <div className="panel">
                <div className='image'>
                    <img src="/pesawallet.png" alt="Pesa Wallet" />
                </div>
                <div className='content'>
                    <h2>Pesa Wallet</h2>
                    <p>A personal budgeting, expense tracking and savings goals tool. This project arose out of a personal need to improve spending habits and track my budget. Created using Django Python framework and MongoDB on the backend and React JS on the frontend.</p>
                    <button onClick={() => window.open('https://github.com/K-Musembi/financial-app', '_blank')}>GitHub</button>
                </div>
            </div>

            <div className="panel">
                <div className='image'>
                    <img src="/mazaomema.jpg" alt="Mazao Mema" />
                </div>
                <div className='content'>
                    <h2>Mazao Mema</h2>
                    <p>An agri-info platform, to offer marketplace information to local farmers. Flask Python framework and SQL on the backend. Work in progress.</p>
                    <button onClick={() => window.open('https://github.com/K-Musembi/Mazao_Mema', '_blank')}>GitHub</button>
                </div>
            </div>
        </div>
    );
};

export default Projects;
